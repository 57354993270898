import { FulfillmentType, Industry } from '../types';

export const YEAR_COUNT = 4;

export const INITIAL_CAPEX = 0;

export const ITEM_FEE_3PL = 0.62496361;
export const ORDER_FEE_3PL = 1.981803005;

// Costs
export const BIN_COST = 15;
export const UPFRONT_COST_ADDITIONAL = 15000;
export const INSTALLATION_COST = 0.25;

// Validation rules
export const DAILY_ORDER_MAX = 600;
// Changed this from 100 to 1, will have to see if it causes issues
export const DAILY_ORDER_MIN = 1;
export const DAILY_ORDER_DEFAULT = 200;
export const FULFILLMENT_TYPE_DEFAULT: FulfillmentType = '3pl';
export const INDUSTRY_DEFAULT: Industry = 'fashion';
export const SKU_DEFAULT = 3000;
export const SKU_MIN = 1;
export const SKU_MAX = 10000;
