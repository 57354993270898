/**
 * Anything in this file will probably be moved to Sanity
 */

import { BusinessType, Country, Industry, MerchantType } from '../types';
import {
  DAILY_ORDER_DEFAULT,
  FULFILLMENT_TYPE_DEFAULT,
  INDUSTRY_DEFAULT,
  SKU_DEFAULT
} from './constants';
import { BasicInput, Input } from './types';

// TODO find out where these should come from
export const binsPerHour = 130; // TODO: This to Sanity
export const binsPerYear = binsPerHour * 40 * 4.3 * 12;
/** Master_CF_overview C97 */
export const inbound = binsPerYear * 0.2;
/** Master_CF_overview C98 */
export const outbound = binsPerYear * 0.8;

export const pickManual = 33; // BCG assumption
export const pioPickPerFee = 0.5;
export const reductionErrorPickingPio = 0.5; // 50%
export const costOfErrorPick = 20; // In €;
export const storageFeeBin = 1.5;
export const returnOrderFee = 0.5;
export const itemsAdjFirstItemPick = 1.0;

/** Master_CF_overview B56:C63 */
export const warehouseFTECostPerYear = {
  NO: 42.91585127,
  SE: 36.49508841,
  DK: 38.01571709,
  FI: 39.99,
  DE: 35.0,
  UK: 41.4,
  US: 48.375
};

/** Master_CF_overview B66:C73 */
export const warehouseRentalCostPerMonth = {
  NO: 9.83333333333333,
  SE: 6.91666666666667,
  DK: 7.6,
  FI: 10.0,
  DE: (76 + 86 + 82 + 55) / 4 / 12,
  UK: (91 + 91 + 176) / 3 / 12,
  US: 10.8
};

/** 3PL fees_table G3:M9 */
export const compInputs3PL = {
  1000: { dailyOrders: 33, orderFee: 2.2, itemFee: 0.7, storageFeeBin: 1.5 },
  2500: {
    dailyOrders: 83,
    orderFee: 2.06391070779379,
    itemFee: 0.698774476697337,
    storageFeeBin: 1.5
  },
  5000: {
    dailyOrders: 167,
    orderFee: 1.97401995149127,
    itemFee: 0.680292123756161,
    storageFeeBin: 1.5
  },
  7500: {
    dailyOrders: 250,
    orderFee: 1.86314993936409,
    itemFee: 0.646277517568353,
    storageFeeBin: 1.5
  },
  10000: {
    dailyOrders: 333,
    orderFee: 1.76999244239588,
    itemFee: 0.613963641689935,
    storageFeeBin: 1.5
  },
  17000: { dailyOrders: 567, orderFee: 1.5, itemFee: 0.55, storageFeeBin: 1.5 }
};

type BusinessTypeMapping = Partial<
  Record<Industry, Record<BusinessType, number>>
>;

// map to find the default values based on user input of the first questions
export const businessMap: BusinessTypeMapping = {
  fashion: { b2b: 11.0, b2c: 2.1 },
  beauty: { b2b: 9.0, b2c: 1.5 },
  electronics: { b2b: 10.0, b2c: 3.0 },
  sport: { b2b: 11.0, b2c: 2.0 },
  home: { b2b: 11.0, b2c: 2.5 }
};

type MerchantTypeMapping = Partial<
  Record<Industry, Record<MerchantType, number>>
>;

export const merchantTypeMap: MerchantTypeMapping = {
  fashion: { d2c: 2.5, reseller: 1.0 },
  beauty: { d2c: 1.5, reseller: 0.6 },
  electronics: { d2c: 1.5, reseller: 0.6 },
  sport: { d2c: 3.0, reseller: 1.2 },
  home: { d2c: 3.0, reseller: 1.2 }
};

export const initialInput: Input = {
  annualGrowth: 15,
  bins: 2500,
  country: 'SE',
  industry: INDUSTRY_DEFAULT,
  fulfillmentType: FULFILLMENT_TYPE_DEFAULT,
  merchantType: 'd2c',
  dailyOrders: DAILY_ORDER_DEFAULT,
  errorRate: 0.5,
  fteCount: 0.8804785331,
  fteCost: 36.495088,
  b2bShare: 0,
  orderlinesB2b: 11.0,
  orderlinesB2c: 2.1,
  returnRate: 30,
  skuCount: SKU_DEFAULT,
  warehouseHeight: 3.5,
  warehouseRent: 6.91666666666667,
  warehouseSize: 350
};

export const initialBasicInput: BasicInput = {
  industry: INDUSTRY_DEFAULT,
  fulfillmentType: FULFILLMENT_TYPE_DEFAULT,
  skuCount: SKU_DEFAULT,
  dailyOrders: DAILY_ORDER_DEFAULT
};

export const merchantTypes: Record<MerchantType, string> = {
  d2c: 'D2C',
  reseller: 'Reseller'
};

export const countries: Record<Country, string> = {
  DE: 'Germany',
  DK: 'Denmark',
  FI: 'Finland',
  NO: 'Norway',
  SE: 'Sweden',
  UK: 'United Kingdom',
  US: 'United States'
};

export const upfrontCostMap = {
  10000: 7.2,
  9000: 8.0,
  8000: 8.8,
  7000: 9.6,
  6000: 10.4,
  5000: 11.2,
  4000: 12.0,
  3000: 12.8,
  2000: 13.6,
  1000: 14.4,
  900: 17.6,
  800: 20.8,
  700: 24.0,
  600: 27.2,
  500: 30.4,
  400: 33.6,
  300: 36.8,
  200: 40.0
};
