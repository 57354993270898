import axios from 'axios';

import {
  hubspotContactCategoryTypeMap,
  hubspotCustomerFieldMap,
  hubspotFulfillmentTypeMap,
  hubspotIndustryMap,
  hubspotMerchantTypeMap
} from '../hubspot/maps';
import { CustomerData, HubspotCustomerFields } from '../hubspot/types';
import { Industry } from '../types';

function isIndustryArray(
  industry: Industry | Industry[]
): industry is Industry[] {
  return Array.isArray(industry);
}
export default class HubspotAPI {
  private static mapCustomerDataToHubspotFields(
    data: Partial<CustomerData>
  ): HubspotCustomerFields {
    // Make a copy just to be sure
    const values = { ...data };
    // Remove any inhouse specific fields from being passed to Hubspot
    if (values.fulfillmentType === '3pl') {
      delete values.fulfillmentShifts;
      delete values.fulfillmentWorkforce;
      delete values.errorRate;
      delete values.warehouseHeight;
      delete values.warehouseSize;
      delete values.warehouseRent;
    }

    return Object.keys(values).reduce((map, key) => {
      const hubspotField =
        hubspotCustomerFieldMap[key as keyof typeof hubspotCustomerFieldMap];

      // Industries need to be passed as a semi-colon-separated-string
      if (key === 'industry') {
        const stringValues = isIndustryArray(values[key] ?? [])
          ? (values[key] as Industry[])
              .map(i => hubspotIndustryMap[i])
              .join(';')
          : values[key];
        return {
          ...map,
          [hubspotField]: stringValues
        };
      }
      // Map fulfillment types to their list values
      if (key === 'fulfillmentType') {
        return {
          ...map,
          [hubspotField]:
            hubspotFulfillmentTypeMap[
              values[key] as keyof typeof hubspotFulfillmentTypeMap
            ]
        };
      }
      // Map merchant types to their list values
      if (key === 'merchantType') {
        return {
          ...map,
          [hubspotField]:
            hubspotMerchantTypeMap[
              values[key] as keyof typeof hubspotMerchantTypeMap
            ]
        };
      }
      // Map contact category types to their list values
      if (key === 'contactCategory') {
        return {
          ...map,
          [hubspotField]:
            hubspotContactCategoryTypeMap[
              values[key] as keyof typeof hubspotContactCategoryTypeMap
            ]
        };
      }
      // Map any of the other given fields to their matching Hubspot field (if defined)
      if (typeof hubspotField !== 'undefined') {
        return { ...map, [hubspotField]: values[key as keyof CustomerData] };
      }
      return map;
    }, {});
  }

  /*
   * Updates customer data in Hubspot using an e-mail address
   * as a user identifier.
   * This should match the one from createGridDesign on SalesforceAPI in most cases.
   */
  static async updateCustomerData(
    companyEmail: string,
    customerData: Partial<CustomerData>,
    reCaptchaToken?: string
  ) {
    if (!companyEmail) {
      return;
    }

    if (reCaptchaToken) {
      await axios.post(`/api/reCaptcha`, {
        reCaptchaToken
      });
    }

    const requestBody = this.mapCustomerDataToHubspotFields(customerData);
    const searchResponse = await axios.post(`/api/contacts/search`, {
      email: companyEmail
    });
    const hubspotID = searchResponse?.data?.id;
    if (hubspotID) {
      return axios.patch(`/api/contacts/${hubspotID}`, requestBody);
    }
    return axios.post(`/api/contacts/`, requestBody);
  }
}
