import { OptionTypeBase } from '@components/inputs/SelectField';

import { hubspotIndustryMap } from '../hubspot/maps';
import { Industry } from '../types';

const INDUSTRY_OTHER = 'other';

const industries: Partial<Record<Industry, string>> = {
  beauty: 'Beauty & Personal Care',
  electronics: 'Consumer Electronics',
  fashion: 'Fashion & Apparel',
  home: 'Home Supplies',
  sport: 'Sport & Outdoor'
};

export const industriesWithOther: Partial<Record<Industry, string>> = {
  ...hubspotIndustryMap,
  [INDUSTRY_OTHER]: 'Other'
};

export const industryOptions: OptionTypeBase[] = Object.keys(industries).map(
  key => ({
    value: key,
    label: industries[key as keyof typeof industries] ?? ''
  })
);
