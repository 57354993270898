import {
  ContactCategory,
  Country,
  FulfillmentType,
  Industry,
  MerchantType
} from '@types';

import {
  CustomerData,
  HubspotContactCategory,
  HubspotCountry,
  HubspotCustomerFields,
  HubspotFulfillmentType,
  HubspotIndustry,
  HubspotMerchantType
} from './types';

export const hubspotCountryMap: Record<Country, HubspotCountry> = {
  DE: 'Germany',
  DK: 'Denmark',
  FI: 'Finland',
  NO: 'Norway',
  SE: 'Sweden',
  UK: 'United Kingdom',
  US: 'USA'
};

export const hubspotMerchantTypeMap: Record<MerchantType, HubspotMerchantType> =
  {
    d2c: 'D2C',
    reseller: 'Retail'
  };

export const hubspotFulfillmentTypeMap: Record<
  FulfillmentType,
  HubspotFulfillmentType
> = {
  '3pl': '3PL',
  inhouse: 'In-House'
};

export const hubspotIndustryMap: Partial<Record<Industry, HubspotIndustry>> = {
  fashion: 'Fashion & Apparel',
  beauty: 'Beauty & Personal care',
  electronics: 'Consumer electronics',
  sport: 'Sport & Outdoor',
  home: 'Home supplies'
};

export const hubspotContactCategoryTypeMap: Partial<
  Record<ContactCategory, HubspotContactCategory>
> = {
  demo: 'Getting a demo',
  financing: 'Financing and savings',
  integration: 'Integration',
  installation: 'Installation',
  businessSuitability: 'The suitability of my business'
};

export const hubspotCustomerFieldMap: Record<
  keyof Omit<CustomerData, 'orderlinesB2b' | 'orderlinesB2c'>, // omitting these values for now until further notice
  keyof HubspotCustomerFields
> = {
  averageOrderValue: 'average_value_per_order',
  averageOrderValueCurrency: 'average_order_value_currency',
  b2bShare: 'b2b_share_of_orders',
  bins: 'of_bins',
  companyEmail: 'email',
  companyURL: 'website__c',
  contactCategory: 'i_would_like_to_know_more_about',
  dailyOrders: 'daily_order_volume',
  errorRate: 'error_rate',
  fteCost: 'cost_per_fte',
  fulfillmentShifts: 'of_8_hour_shifts',
  fulfillmentType: 'fulfillment_method',
  fulfillmentWorkforce: 'of_ftes_who_work_with_fulfillment',
  firstName: 'firstname',
  height: 'warehouse_height',
  lastName: 'lastname',
  industry: 'industry',
  industryOther: 'other_industry_type',
  isNewsletterSubscribed: 'newsletter__c',
  itemsFitInBin: 'of_items_fit_in_a_pio_bin',
  length: 'length',
  merchantType: 'type_of_merchant',
  message: 'lead_message',
  numberOrders: 'total_orders_per_year',
  numberOrderLines: 'order_lines_per_average_order__builder_',
  phone: 'phone',
  platform: 'cms_supplier',
  platformOther: 'other_cms_supplier',
  returnRate: 'return_rate',
  shipper: 'shipment_software_supplier',
  shipperOther: 'other_shipment__software_supplier',
  skuCount: 'of_skus_you_store',
  warehouseAddress: 'address',
  warehouseCity: 'city__c',
  warehouseCountry: 'country__c',
  warehouseHeight: 'warehouse_height',
  warehousePostcode: 'zip',
  warehouseRent: 'monthly_rent_per_sqm_month',
  warehouseSize: 'warehouse_size__sqm_',
  width: 'width',
  yearlyGrowthEstimate: 'estimated_annual_growth_next_3_years'
};
