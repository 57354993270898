import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const DEFAULT_CURRENCY = 'USD';

export type Currency = 'EUR' | 'USD';

interface CurrencyState {
  currency: 'EUR' | 'USD';
  setCurrency: (currency: Currency) => void;
}

const useCurrencyStore = create<
  CurrencyState,
  [['zustand/persist', CurrencyState]]
>(
  persist(
    set => ({
      currency: DEFAULT_CURRENCY,
      setCurrency: currency => set({ currency })
    }),
    {
      name: 'pio-currency-store'
    }
  )
);

export default useCurrencyStore;
