import { FulfillmentType } from '../types';

export const fulfillmentTypes: Record<FulfillmentType, string> = {
  '3pl': 'By 3PL',
  inhouse: 'In-house'
};

export const fulfillmentTypeOptions = Object.keys(fulfillmentTypes).map(
  key => ({
    value: key,
    label: fulfillmentTypes[key as keyof typeof fulfillmentTypes]
  })
);

export const fulfillmentShiftOptions = [
  { value: '1', label: '1 shift (8 hours)' },
  { value: '2', label: '2 shifts (16 hours)' },
  { value: '3', label: '3 shifts (24 hours)' }
] as const;

export type FulfillmentShiftValue =
  (typeof fulfillmentShiftOptions)[number]['value'];
