const map = (from: number[], to: number[], s: number) =>
  to[0] + ((s - from[0]) * (to[1] - to[0])) / (from[1] - from[0]);

export const lerp = (value: number, target: number, amount: number) =>
  (1 - amount) * value + amount * target;

export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

export const getMotionValue = (from: number[], to: number[], s: number) => {
  if (to[1] < to[0]) {
    const val = map(from, [...to].reverse(), s);
    return clamp(to[1] + (to[0] - val), to[1], to[0]);
  } else {
    const val = map(from, to, s);
    return clamp(val, to[0], to[1]);
  }
};

export const getScrollPos = () => {
  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  return { scrollTop, scrollLeft };
};

export function diff(num1: number, num2: number) {
  return num1 > num2 ? num1 - num2 : num2 - num1;
}

export function round(num = 0, maxPrecision = 100) {
  return ~~(num * maxPrecision) / maxPrecision;
}
