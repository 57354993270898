import cx from 'classnames';
import { useField } from 'formik';
import { InputHTMLAttributes } from 'react';

import inputStyles from './InputField.module.scss';
import styles from './TextareaField.module.scss';

interface TextareaFieldProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  className?: string;
  showErrorIfExists?: boolean;
  disabled?: boolean;
}

const TextareaField: React.FC<TextareaFieldProps> = ({
  name,
  className,
  showErrorIfExists = true,
  disabled,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const fieldHasError = (meta.error && meta.touched) || meta.initialError;
  const errorMessage = meta.error || meta.initialError;

  return (
    <div className={inputStyles.inputWrapper}>
      <textarea
        {...field}
        {...rest}
        className={cx(inputStyles.input, styles.textarea, className, {
          [inputStyles.error]: fieldHasError && !disabled,
          [inputStyles.disabled]: disabled
        })}
      />
      {showErrorIfExists && (
        <div className={inputStyles.errorMessage}>
          {fieldHasError && errorMessage}
        </div>
      )}
    </div>
  );
};

export default TextareaField;
