import useAsyncStore from '@hooks/store/useAsyncStore';
import useCurrencyStore, { Currency } from '@hooks/store/useCurrencyStore';

import SmallSelect from '../../atoms/SmallSelect';

const CurrencyToggle = () => {
  const currency = useAsyncStore(useCurrencyStore, state => state.currency);
  const setCurrency = useCurrencyStore(state => state.setCurrency);
  return (
    <SmallSelect
      options={[
        {
          label: 'Euro (€)',
          activeLabel: 'Euro',
          value: 'EUR'
        },
        {
          label: 'US Dollars ($)',
          activeLabel: 'US Dollars',
          value: 'USD'
        }
      ]}
      value={currency}
      onChange={currency => setCurrency(currency as Currency)}
    />
  );
};

export default CurrencyToggle;
