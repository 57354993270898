import cx from 'classnames';
import { useField } from 'formik';
import { useId } from 'react';
import ReactSelect, {
  GroupBase,
  Props as ReactSelectProps
} from 'react-select';

import styles from './SelectField.module.scss';

export type OptionTypeBase = { label: string; value: string };

const SelectField = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  className,
  label,
  placeholder = 'Please select one',
  showErrorIfExists = true,
  ...props
}: ReactSelectProps<Option, IsMulti, Group> & {
  label?: string;
  showErrorIfExists?: boolean;
}) => {
  const [field, meta, helpers] = useField(name ?? '');
  const fieldHasError = (meta.error && meta.touched) || meta.initialError;
  const errorMessage = meta.error || meta.initialError;
  const combinedClassName = cx(styles.select, className, {
    [styles.labelShown]: !!label
  });

  return (
    <div className={styles.inputWrapper}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <ReactSelect
        {...field}
        {...props}
        instanceId={useId()}
        placeholder={placeholder}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor:
              showErrorIfExists && fieldHasError ? '#ff395d' : '#d1d0cd',
            borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px'
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen
              ? 'rotate(180deg)'
              : undefined
          }),
          indicatorsContainer: provided => ({
            ...provided,
            marginRight: '8px'
          }),
          menu: provided => ({
            ...provided,
            borderRadius: '0 0 4px 4px'
          }),
          valueContainer: provided => ({
            ...provided,
            padding: !label ? '28px 16px' : '40px 16px 16px 16px'
          })
        }}
        value={
          props.options?.find(
            option => (option as Option).value === field.value
          ) as Option | undefined
        }
        onChange={option => helpers.setValue((option as Option).value)}
        classNamePrefix="select"
        className={combinedClassName}
      />
      {showErrorIfExists && (
        <div className={styles.errorMessage}>
          {fieldHasError && errorMessage}
        </div>
      )}
    </div>
  );
};

export default SelectField;
