import cx from 'classnames';
import { Field, useField } from 'formik';
import { Check } from 'react-feather';

import styles from './CheckboxField.module.scss';

interface CheckboxFieldProps {
  className?: string;
  label: string;
  name: string;
  value?: boolean | string;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  className,
  label,
  name,
  value,
  ...props
}) => {
  const [field] = useField(name);
  return (
    <label className={cx(styles.root, className)}>
      <Field
        {...field}
        {...props}
        className={styles.checkbox}
        type="checkbox"
        name={name}
        value={value}
      />
      {label}
      <Check
        className={styles.check}
        height={16}
        width={16}
        color={'#111e2d'}
      />
    </label>
  );
};

export default CheckboxField;
