import * as Yup from 'yup';

import { fulfillmentTypes } from '../data/fulfillment';
import { industriesWithOther } from '../data/industries';
import {
  DAILY_ORDER_MAX,
  DAILY_ORDER_MIN,
  SKU_MAX,
  SKU_MIN
} from '../roi/constants';
import { merchantTypes } from '../roi/data';
import { formatNumber } from '../utils/numberUtils';
import { emailDomainSchema } from './emailDomainValidator';

export const nameSchema = Yup.string()
  .max(30, 'Must be 30 characters or less')
  .required('Name is required');
export const emailSchema = emailDomainSchema;
export const urlSchema = Yup.string()
  .matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Please enter a valid url'
  )
  .nullable()
  .transform((v, o) => (o === '' ? null : v));
// We try to validate empty strings, but we don't care so we transform it to null and ignore
export const fulfillmentTypeSchema = Yup.string()
  .oneOf(Object.keys(fulfillmentTypes))
  .label('Fulfillment type')
  .required();
export const fulfillmentShiftsSchema = Yup.string().required();
export const fulfillmentWorkforceSchema = Yup.number().required();
export const growthSchema = Yup.number().required();
export const industrySchema = Yup.string().oneOf(
  Object.keys(industriesWithOther)
);
export const industryOtherSchema = Yup.string().required();
export const industriesSchema = Yup.array()
  .of(industrySchema)
  .min(1)
  .required();
export const itemsSchema = Yup.number().min(0).max(100).required();
export const merchantTypeSchema = Yup.string()
  .oneOf(Object.keys(merchantTypes))
  .label('Merchant type');
export const SKU_COUNT_MAX_MESSAGE = `Our calculator doesn't support values greater than ${formatNumber(
  SKU_MAX,
  0
)}.`;
export const skuCountSchema = Yup.number()
  .min(
    SKU_MIN,
    `Our calculator doesn't support values smaller than ${formatNumber(
      SKU_MIN,
      0
    )}.`
  )
  .max(SKU_MAX, SKU_COUNT_MAX_MESSAGE)
  .label('SKUs')
  .required('Please enter your number of SKUs.');
export const averageOrderValueSchema = Yup.number()
  .typeError('Value must be a valid number')
  .positive('Value must be a positive number')
  .required('Value is required')
  .nullable();
export const averageOrderCurrencySchema = Yup.string().required();
export const countrySchema = Yup.string().required();
export const streetSchema = Yup.string();
export const citySchema = Yup.string();
export const postcodeSchema = Yup.string();
export const DAILY_ORDERS_MAX_MESSAGE = `Our calculator doesn't support values greater than ${formatNumber(
  DAILY_ORDER_MAX,
  0
)}.`;
export const dailyOrdersSchema = Yup.number()
  .required('Please enter your estimated daily orders.')
  .min(
    DAILY_ORDER_MIN,
    `Our calculator doesn't support values smaller than ${formatNumber(
      DAILY_ORDER_MIN,
      0
    )}.`
  )
  .max(DAILY_ORDER_MAX, DAILY_ORDERS_MAX_MESSAGE)
  .label('Daily orders');

export const userSchema = Yup.object({
  fullName: nameSchema,
  companyEmail: emailSchema,
  companyURL: urlSchema
});

export const firstNameSchema = Yup.string()
  .max(30, 'Must be 30 characters or less')
  .required('First name is required');
export const lastNameSchema = Yup.string()
  .max(30, 'Must be 30 characters or less')
  .required('Last name is required');

export const newUserSchema = Yup.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  companyEmail: emailSchema,
  companyURL: urlSchema
});
