import { DEFAULT_SEO_META } from '@constants';
import { NextSeo } from 'next-seo';

import { SeoType } from '../../types';

const SeoMeta: React.FC<SeoType & { url?: string }> = ({
  title = DEFAULT_SEO_META.title,
  description = DEFAULT_SEO_META.description,
  url = DEFAULT_SEO_META.url,
  image = DEFAULT_SEO_META.image,
  noindex = false,
  nofollow = false
}) => (
  <NextSeo
    title={title}
    description={description}
    openGraph={{
      url,
      title,
      description,
      images: [
        {
          url: image as string,
          alt: description,
          width: 1200,
          height: 630
        }
      ],
      site_name: DEFAULT_SEO_META.siteName
    }}
    twitter={{
      cardType: 'summary_large_image'
    }}
    noindex={noindex}
    nofollow={nofollow}
  />
);

export default SeoMeta;
