import cx from 'classnames';
import { useField } from 'formik';
import { InputHTMLAttributes } from 'react';

import styles from './InputField.module.scss';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  showErrorIfExists?: boolean;
  submitButton?: JSX.Element;
  disabled?: boolean;
  wrapperClassName?: string;
}

const InputField = ({
  name,
  className,
  label,
  showErrorIfExists = true,
  submitButton,
  disabled,
  type = 'text',
  wrapperClassName,
  ...props
}: InputFieldProps) => {
  const [field, meta] = useField(name);
  const fieldHasError = (meta.error && meta.touched) || meta.initialError;
  const errorMessage = meta.error || meta.initialError;
  return (
    <div className={cx(styles.inputWrapper, wrapperClassName)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        type={type}
        {...props}
        {...field}
        className={cx(styles.input, className, {
          [styles.error]: fieldHasError && !disabled,
          [styles.labelShown]: !!label,
          [styles.disabled]: disabled
        })}
      />
      {submitButton && submitButton}
      {showErrorIfExists && (
        <div className={styles.errorMessage}>
          {fieldHasError && errorMessage}
        </div>
      )}
    </div>
  );
};

export default InputField;
