import { Currency } from '../hooks/store/useCurrencyStore';
import { UnitType } from '../types';

export const isBetween = (
  value: number,
  start: number,
  end: number
): boolean => {
  const min = Math.min(start, end);
  const max = Math.max(start, end);
  return value >= min && value <= max;
};

export const metersToFeet = (meters: number) => meters * 3.281;

export const feetToMeters = (feet: number) => feet / 3.281;

export const round = (num: number, decimals: number) => {
  const p = Math.pow(10, decimals);
  const n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

export const toUnit = (value: number, unitType: UnitType) => {
  let valueToTransform = value;
  if (unitType === 'ft') {
    valueToTransform = metersToFeet(value);
  }

  return round(valueToTransform, 2).toFixed(2);
};

export const EUR_TO_USD_RATE = 1.09;
export const USD_TO_EUR_RATE = 1 / EUR_TO_USD_RATE;

export const toPrice = (
  value: number,
  decimals = 2,
  round?: number,
  currency?: Currency
): string => {
  let price = value;
  // TODO: Use a currency API
  if (currency === 'USD') {
    price = price * EUR_TO_USD_RATE;
  }
  if (round) {
    // If round = 100, prices will be rounded to the nearest 100
    price = Math.ceil(price / round) * round;
  }
  // May need to dynamically set locale if more currencies
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'EUR',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(price);
};

export const formatNumber = (value: number, decimals = 2): string => {
  return new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value);
};

export const toRange = (
  value: number,
  range: { min: number; max: number; step?: number }
) => {
  const { min, max, step = 1 } = range;
  return Math.max(min, Math.min(max, Math.ceil(value / step) * step));
};

export const nFormatter = (value: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return value >= item.value;
    });
  return item
    ? (value / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};
