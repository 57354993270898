import Text from '@components/atoms/Text';
import Button from '@components/Button';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { ChevronDown } from 'react-feather';

import styles from './SmallSelect.module.scss';

const dropdownTransition = {
  type: 'easeIn',
  duration: 0.3
};

interface SmallSelectProps {
  options: ReadonlyArray<{
    label: string;
    activeLabel: string;
    value: string;
  }>;
  onChange: (value: string) => void;
  value: string;
}

const SmallSelect = ({ options, onChange, value }: SmallSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (value: string) => {
    setIsOpen(false);
    onChange(value);
  };
  return (
    <div className={styles.toggleWrapper}>
      <Button
        className={styles.selectButton}
        type="button"
        variant="toggle"
        onClick={() => setIsOpen(!isOpen)}
      >
        {options.find(option => option.value === value)?.activeLabel}
        <ChevronDown
          className={cx(styles.icon, { [styles.inverted]: isOpen })}
          size={16}
          strokeWidth={3}
        />
      </Button>
      <motion.div
        className={styles.dropdown}
        initial={{ height: 0 }}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={dropdownTransition}
      >
        <motion.ul
          animate={{ opacity: isOpen ? 1 : 0 }}
          transition={dropdownTransition}
          aria-label="currency-menu"
        >
          {options.map(({ label, value }) => (
            <li key={label}>
              <Button
                className={styles.toggleOption}
                variant="toggle"
                type="button"
                onClick={() => handleClick(value)}
              >
                <Text className={styles.label} variant="b2" as="span">
                  {label}
                </Text>
              </Button>
            </li>
          ))}
        </motion.ul>
      </motion.div>
    </div>
  );
};

export default SmallSelect;
